<template>
  <div class="undertaking">
    <div class="title">请签署承诺书</div>
    <div class="main">
      <div class="ql-editor" v-html="postData.commitmentLetter"></div>
    </div>
    <div class="sign">
      <signCanvas
        ref="esign"
        :width="614"
        :height="242"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor.sync="bgColor"
      />
    </div>
    <div class="submit" @click="handleGenerate">提交申请</div>

    <!-- <v-popup class="popup" v-model="showSign" height="100%">
      <div class="popup-warpper">
        <div class="popup-title"><span>电子签名</span></div>
        <div class="popup-content"></div>
      </div>
    </v-popup> -->
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import wx from "weixin-js-sdk";
import signCanvas from "@/components/bussiness/signCanvas";
import { base64toFile } from "@/utils/utils.js";
import {
  getCommitmentLetterDetailsUrl,
  applyForCommitmentLetterUrl,
  ifCommitmentLetterUrl,
} from "./api";

export default {
  components: {
    signCanvas,
  },
  data() {
    return {
      activityId: "",
      isCrop: false,
      lineWidth: 6,
      postData: {
        commitmentLetter: "",
      },
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      uploadUrl: "/gateway/blade-resource/oss/endpoint/put-file",
      signImageUrl: "",
      resultImg: "",
      result: undefined, // 用于记录承诺书是否已签署
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  async created() {
    this.activityId = this.$route.query.activityId;
    // this.result = await this.ifCommitmentLetter(this.activityId);
    // console.log(result, "result---");
    // if (this.result) {
    //   this.$router.go(-1);
    // }
    this.getDetailInfo();
  },
  methods: {
    // 提交承诺书签名
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          console.log(base64toFile(this.resultImg), "009");
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(this.uploadUrl, formData);
      if (res.code == 200) {
        this.signImageUrl = res.data.link;
        this.postSignData();
      }
    },
    // 清空签名
    handleReset() {
      this.$refs.esign.reset();
    },
    // 签署承诺书接口
    async postSignData() {
      // if (this.result) {
      //   wx.miniProgram.navigateTo({
      //     url: `/xubPages/activityDetail/index?params=${JSON.stringify({
      //       activityId: this.activityId,
      //     })}`,
      //   });
      // }
      let params = {
        userId: this.userId,
        activityId: this.activityId,
        signatureImage: this.signImageUrl,
      };
      let res = await this.$axios.get(applyForCommitmentLetterUrl, {
        params,
      });
      if (res.code === 200) {
        this.$toast({ message: "提交成功", duration: 2000 });
        // this.$router.go(-1);
        let params = {
          activityId: this.activityId,
        };
        // wx.miniProgram.navigateTo({
        //   url: `/xubPages/activityDetail/index?params=${JSON.stringify(
        //     params
        //   )}`,
        // });
        wx.miniProgram.navigateBack({
          delta: 1,
        });
      }
    },
    // 获取承诺书详情详细
    async getDetailInfo() {
      let res = await this.$axios.get(getCommitmentLetterDetailsUrl, {
        params: {
          activityId: this.activityId,
        },
      });
      if (res.code === 200) {
        this.postData = res.data;
      }
    },
    // 判断用户是否已签署承诺书
    async ifCommitmentLetter(activityId) {
      const res = await this.$axios.post(ifCommitmentLetterUrl, null, {
        params: {
          activityId,
          userId: this.userId,
        },
      });
      return res.data;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .ql-editor {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 28px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong {
    font-weight: bolder;
  }
  img {
    width: 100%;
  }
}
.undertaking {
  padding: 72px 68px 138px 68px;
  margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .title {
    font-size: 36px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
  }
  .main {
    box-sizing: border-box;
    margin-top: 22px;
    height: 650px;
    // padding: 48px 32px;
    border: 2px solid #979797;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .sign {
    // width: 614px;
    width: 100%;
    margin: 48px auto 0;
    display: flex;
    justify-content: center;
  }
  .submit {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    height: 66px;
    font-size: 28px;
    line-height: 66px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    border-radius: 10px;
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .popup {
    .popup-warpper {
      height: 100%;
      background: #f8f8f8;
      .popup-title {
        padding: 32px;
        height: 44px;
        line-height: 40px;
        font-size: 32px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
      .popup-content {
        height: calc(100% - 108px);
      }
    }
  }
}
</style>
